import {animateInViewport} from "../transition";

window.initFilters = function initFilters( $root, markers ) {
	function findOne( haystack, arr ) {
		return arr.some( function( v ) {
			return haystack.indexOf( v ) >= 0;
		} );
	}

	function getActiveIds( filterGroups ) {
		var activeIds = [];
		for ( var markerIndex = 0; markerIndex < markers.length; markerIndex++ ) {
			var activeMarker = true;

			for ( var filterIndex = 0; filterIndex < filterGroups.length; filterIndex++ ) {
				var key = filterGroups[ filterIndex ].name;
				var values = filterGroups[ filterIndex ].values;

				if ( values.length > 0 ) {
					if ( !findOne( markers[ markerIndex ][ key ], values ) ) {
						activeMarker = false;
					}
				}
			}

			if ( activeMarker ) {
				activeIds.push( markers[ markerIndex ].id );
			}
		}
		return activeIds;
	}

	var getFilterGroups = function() {
		var filterGroups = [];
		$root.find( '.filter-group' ).each( function( filterGroupIndex ) {
			filterGroups[ filterGroupIndex ] = {};
			filterGroups[ filterGroupIndex ].name = $( this ).attr( 'data-filter-group-name' );
			filterGroups[ filterGroupIndex ].values = [];
			$( this ).find( '.filter' ).each( function() {
				filterGroups[ filterGroupIndex ].values.push( $( this ).attr( 'data-filter-name' ) );
			} );
		} );
		return filterGroups;
	};

	var getActiveFilterGroups = function() {
		var filterGroups = [];
		$root.find( '.filter-group' ).each( function( filterGroupIndex ) {
			filterGroups[ filterGroupIndex ] = {};
			filterGroups[ filterGroupIndex ].name = $( this ).attr( 'data-filter-group-name' );
			filterGroups[ filterGroupIndex ].values = [];
			$( this ).find( '.filter.filter-checked' ).each( function() {
				var value = $( this ).attr( 'data-filter-name' );
				if ( value === undefined || value === null ) {
					return;
				}
				filterGroups[ filterGroupIndex ].values.push( value );
			} );
		} );
		return filterGroups;
	};

	var getPossibleFilterGroups = function( activeFilterGroups ) {
		var possibleFilterGroups = [];

		getFilterGroups().forEach( function( filterGroup, filterGroupIndex ) {
			var currentPossibleFilterGroups = {
				name: filterGroup.name,
				values: []
			};
			filterGroup.values.forEach( function( filter ) {
				var testFilterGroups = cloneFilterGroups( activeFilterGroups );
				testFilterGroups[ filterGroupIndex ].values = [];
				testFilterGroups[ filterGroupIndex ].values.push( filter );

				if ( getActiveIds( testFilterGroups ).length ) {
					currentPossibleFilterGroups.values.push( filter );
				}
			} );
			possibleFilterGroups.push( currentPossibleFilterGroups );
		} );

		return possibleFilterGroups;
	};

	function cloneFilterGroups( filterGroups ) {
		var clone = [];
		filterGroups.forEach( function( filterGroup ) {
			clone.push( {
				name: filterGroup.name,
				values: filterGroup.values.slice()
			} );
		} );
		return clone;
	}

	$( document ).click( function( event ) {
		if ( !$( event.target ).closest( '.filter-group' ).length ) {
			$root.find( '.filter-items-container' )
				.stop().slideUp( 'fast' )
				.attr( 'data-toggle-slide-direction', 'down' );
			$root.find( '.filter-group' ).find( '.filter-group-arrow' )
				.removeClass( '-rotate-90' ).addClass( 'rotate-90' );
		}
	} );

	$root.find( '.filter-group .filter-group-toggle' ).on( 'click', function() {
		var $this = $( this );

		$root.find( '.filter-items-container' ).stop().slideUp( 'fast' );
		$this.parents( '.filter-group' )
			.find( '.filter-items-container' ).stop().slideToggle( 'fast' );
	} );

	$root.find( '.filter-group .filter' ).on( 'click', function( event ) {
		event.preventDefault();
		var $this = $( this );

		if ( $this.hasClass( 'filter-disabled' ) ) {
			return;
		}

		$this.parents( '.filter-items-container' )
			.find( '.filter' )
			.removeClass( 'filter-checked' );
		if ( $this.hasClass( 'filter-single' ) ) {
			$this.addClass( 'filter-checked' );
		}

		updateFilters();
		$this.parents( '.filter-group' )
			.find( '.filter-items-container' ).stop().slideUp( 'fast' );
		$this.parents( '.filter-group' )
			.find( '.filter-group-title' ).html( $this.attr( 'data-filter-name' ) );
	} );

	function updateFilters() {
		var select = getActiveFilterGroups();

		$root.find( '.filter.filter-single' )
			.addClass( 'filter-disabled ' );
		getPossibleFilterGroups( select ).forEach( function( filterGroup ) {
			var $filterGroup = $root.find( '.filter-group[data-filter-group-name="' + filterGroup.name + '"]' )
			filterGroup.values.forEach( function( filter ) {
				$filterGroup.find( '.filter[data-filter-name="' + filter + '"]' )
					.removeClass( 'filter-disabled ' );
			} );
		} );

		var activeIds = getActiveIds( select );
		$root.find( '*[data-location-id]' ).each( function() {
			var $this = $( this );
			$this.toggle( activeIds.indexOf( $this.attr( 'data-location-id' ) ) >= 0 );
		} );
		animateInViewport();

		for ( const swiperElement of document.querySelectorAll( '.filter-carousel' ) ) {
			if ( swiperElement.swiper ) {
				swiperElement.swiper.update()
			}
		}
	}

	updateFilters();
}
