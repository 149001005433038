import {animateInViewport} from "../transition";

$( '.carousel' ).each( function() {
	var $this = $( this );

	var swiper = new Swiper( this, {
		pagination: false,
		navigation: {
			nextEl: $this.parents( '.carousel-container' ).find( '.carousel-next' )[ 0 ],
			prevEl: $this.parents( '.carousel-container' ).find( '.carousel-previous' )[ 0 ],
		},
		initialSlide: 1,
		loop: true,
		autoHeight: true,
		grabCursor: true,
		slidesPerView: 1,
		spaceBetween: 10,
		breakpoints: {
			1024: {
				slidesPerView: 2,
				spaceBetween: 20,
			},
		},
	} );

	swiper.on( 'slideChange slideChangeTransitionEnd', function() {
		animateInViewport();
	} );
} );

$( '.products-carousel' ).each( function() {
	var $this = $( this );

	function updatePagination( swiper ) {
		$( '[data-slide]' )
			.removeClass( 'active' )
			.eq( swiper.realIndex )
			.addClass( 'active' );
	}

	var swiper = new Swiper( this, {
		pagination: false,
		navigation: {
			nextEl: $this.parents( '.carousel-container' ).find( '.carousel-next' )[ 0 ],
			prevEl: $this.parents( '.carousel-container' ).find( '.carousel-previous' )[ 0 ],
		},
		loop: true,
		autoHeight: true,
		grabCursor: true,
		slidesPerView: 1,
		spaceBetween: 20,
		hashNavigation: {
			watchState: true,
		},
		on: {
			init: updatePagination,
		},
	} );

	swiper.on( 'slideChange slideChangeTransitionEnd', function( swiper ) {
		animateInViewport();
		updatePagination( swiper );
	} );

	$( '[data-slide]' ).on( 'click', function() {
		var $this = $( this );
		swiper.slideTo( $( swiper.el ).find( '#' + $this.attr( 'data-slide' ) ).index() );
	} );
} );

$( '.filter-carousel' ).each( function() {
	var $this = $( this );

	var swiper = new Swiper( this, {
		pagination: false,
		loop: false,
		autoHeight: true,
		grabCursor: false,
		slidesPerView: 1,
		spaceBetween: 0,
		speed: 100,
	} );

	$this.find( '[data-goto-slide]' ).on( 'click', function() {
		var slide = $( this ).attr( 'data-goto-slide' );
		swiper.slideTo( slide );
	} );
} );

