$( function() {
	$( '#test-form' ).find( 'input, textarea, button' ).each( function() {
		var $this = $( this );

		function removeValidationMessage() {
			$this.next( '.form-validation-message' ).remove();
		}

		$this.change( removeValidationMessage );
		$this.keyup( removeValidationMessage );
	} );
	$( '#test-form' ).submit( function( event ) {
		event.preventDefault();

		var $form = $( this );
		var $submit = $form.find( 'button[type="submit"]' );
		$form.find( '.form-validation-message' ).remove();

		$submit.attr( 'disabled', 'disabled' );
		$submit.parent().addClass( 'flex flex-col' );
		$submit.after( '<span class="form-validation-message form-validation-info-message">' + settings.translation.sendingMail + '</span>' );
		let data = new FormData( this );
		fetch( settings.siteAddress + '/helpers/contact/contact.php', {
			method: 'post',
			body: new URLSearchParams( data ).toString(),
			headers: {
				'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
			},
			cache: 'no-store',
			credentials: 'include',
		} ).then( function( response ) {
			$form.find( '.form-validation-message' ).remove();
			return response.text();
		} ).then( function( response ) {
			response = JSON.parse( response );

			if ( response.result === 'error' ) {
				console.error( 'Server response', response );
				if ( response.message ) {
					$submit.after( '<span class="form-validation-message form-validation-error-message">' + response.message + '</span>' );
				}
				if ( response.fields ) {
					for ( const [field, message] of Object.entries( response.fields ) ) {
						$( '#test-form' ).find( '#' + field ).each( function() {
							var $this = $( this );
							$this.after( '<span class="form-validation-message form-validation-error-message">' + message + '</span>' );
						} );
					}
				}

				return;
			}

			$( '#test-form' ).find( 'input' )
				.not( ':button, :submit, :reset, :hidden' )
				.attr( 'value', '' )
				.val( '' )
				.prop( 'checked', false )
				.prop( 'selected', false );

			$submit.after( '<span class="form-validation-message form-validation-success-message">' + settings.translation.mailSent + '</span>' );
			setTimeout( function() {
				$form.find( '.form-validation-message' ).remove();
			}, 5000 );

		} ).catch( function( error ) {
			console.error( error );
			$submit.after( '<span class="form-validation-message form-validation-error-message">' + settings.translation.genericError + '</span>' );
		} ).finally( function() {
			$submit.removeAttr( 'disabled' );
		} );
		return false;
	} );

	$( '#interested-product li' ).on( 'click', function( e ) {
		const selection = $( this ).attr( 'data-name' );
		const offices = officesPerProduct[ selection ];

		$( '#interested-location li' ).removeClass( 'hidden' );
		$( '#interested-location li' ).each( function() {
			if ( !offices.includes( $( this ).attr( 'data-name' ) ) ) {
				$( this ).addClass( 'hidden' );
			}
		} );
	} );
} );

$( function() {
	$( '.contact-filter' ).each( function() {
		var $root = $( this );

		$( document ).click( function( event ) {
			if ( !$( event.target ).closest( '.filter-group' ).length ) {
				$root.find( '.filter-items-container' )
					.stop().slideUp( 'fast' )
					.attr( 'data-toggle-slide-direction', 'down' );
			}
		} );

		$root.find( '.filter-group .filter-group-toggle' ).on( 'click', function() {
			var $this = $( this );

			$root.find( '.filter-items-container' ).stop().slideUp( 'fast' );
			$this.parents( '.filter-group' )
				.find( '.filter-items-container' ).stop().slideToggle( 'fast' );
		} );

		$root.find( '.filter-group .filter' ).on( 'click', function( event ) {
			event.preventDefault();
			var $this = $( this );

			$this.parents( '.filter-items-container' )
				.find( '.filter' )
				.removeClass( 'filter-checked' );
			if ( $this.hasClass( 'filter-single' ) ) {
				$this.addClass( 'filter-checked' );
			}

			$this.parents( '.filter-group' )
				.find( '.filter-items-container' ).stop().slideUp( 'fast' );
			$this.parents( '.filter-group' )
				.find( '.filter-group-title' ).html( $this.attr( 'data-filter-name' ) );
			$root.find( 'input' ).attr( 'value', $this.attr( 'data-filter-name' ) );
		} );
	} );
} );
