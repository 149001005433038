import {animateInViewport} from "./transition";

let jQueryBridget = require( 'jquery-bridget' );
let Masonry = require( 'masonry-layout' );

jQueryBridget( 'masonry', Masonry, $ );

$( function() {
	window.msnry = $( '.masonry' )
		.on( 'layoutComplete', function() {
			animateInViewport();
		} )
		.masonry( {
			itemSelector: '.masonry-item',
			gutter: 24,
			columnWidth: '.masonry-item:not(.hidden):not(.filtered)',
			transitionDuration: 0
		} );
} );
